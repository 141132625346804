import React from "react";

function TextContentMobile() {
  return (
    <div className="mobile-container">
      <div className="main-text-grid">
        <p>
          Gabriel Junqueira Becak (b. 1990) is a Berlin-based artist whose work
          explores perceptual, cognitive and phenomenological distortions.
        </p>
        <p>
          Originally from São Paulo, Brazil, Becak's youth was split between the
          city and the countryside of Minas Gerais and Mato Grosso. After
          graduating in Philosophy, he moved to Germany to dedicate himself to
          artistic research.
        </p>
        <p>
          Becak's multifaceted practice blends sound, language, imagery, and
          scent to engage with tensions at the intersection of mysticism,
          science, conspiracy theories, technology, religion, altered states of
          consciousness, and the limits of meaning and knowledge.
        </p>
      </div>
    </div>
  );
}

export default TextContentMobile;
