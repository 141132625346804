import { Flex, Box, useFlexSize } from "@react-three/flex";
import { Float, Center, useTexture, Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";

import Shark from "./models/Shark.js";
import Candy1 from "./models/Candy1.js";
import Croissant from "./models/Croissant.js";
import Some3dTextStuff from "./Some3dTextStuff.js";
import Model from "./models/Model.js";
// import Candy1 from "/components/models/Candy1.js";

function Gallery() {
  const flexRef = useRef();

  const alu = useTexture("Matcap_Alu_00.png");
  const gold = useTexture("Matcap_Gold_00.png");
  const copper = useTexture("Matcap_Copper_00.png");

  useFrame(({ clock }) => {
    flexRef.current.position.y = (clock.getElapsedTime() * 2) % 20;
  });

  return (
    <>
      {/* <Center position={[0, 0, 5]}> */}
      {/* <Flex> */}
      {/* <Box> */}
      {/* <Float rotationIntensity={1}> */}
      {/* </Float> */}
      {/* </Box> */}
      <Flex
        ref={flexRef}
        dir="column"
        position={[0, 0, 7]}
        // size={[5, 5, 5]}
        // wrap="wrap"
      >
        <RotatingMesh>
          <Model
            scale={3}
            model={`e9be93657274_7552d419111a_a_realistic_tulip__.glb`}
            material={alu}
          />
        </RotatingMesh>
        {/* <Box mt={2}> */}
        {/* <Some3dTextStuff text={`PORTFOLIO ON REQUEST`} /> */}

        {/* </Box> */}
        <RotatingMesh>
          <Model
            scale={3}
            model={`cf7721dc5eb8_6dcf7ba8ed4c_a_realistic_astrona.glb`}
            material={gold}
          />
        </RotatingMesh>
        {/* <Box>
          <Some3dTextStuff text={`INSTA`} />
        </Box> */}
        <RotatingMesh>
          <Model
            scale={3}
            model={`600757af59f8_c1efd12670a5_a_orange_sweet__3d_.glb`}
            material={copper}
          />
        </RotatingMesh>
      </Flex>
    </>
  );
}

export default Gallery;

function RotatingMesh({ children }) {
  const meshRef = useRef();

  useFrame(() => {
    (meshRef.current.rotation.x += 0.05), (meshRef.current.rotation.y += 0.03);
    meshRef.current.rotation.z += 0.02;
  });

  return (
    <Box mt={2} ref={meshRef} centerAnchor>
      {children}
    </Box>
  );
}
