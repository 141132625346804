import { Html } from "@react-three/drei";
import { motion } from "framer-motion";
import { useRef } from "react";
import * as THREE from "three";
import { useWindowSize } from "@uidotdev/usehooks";
import { useFollowPointer } from "./useFollowPointer";

export default function TextContentTest() {
  const container = useRef();
  const { x, y } = useFollowPointer();
  const { width } = useWindowSize();

  const animateProps = (index) => ({
    scale: 1 + Math.min(Math.abs(y * (index * 0.005)), 0.2),
    skew: x * (index * 0.005),
    rotate: (x + y) * (index * 0.01),
    x: x * (index * 0.1),
    y: y * (index * 0.1),
  });

  return (
    <Html
      position={[0, 1, 0]}
      receiveShadow
      transform
      distanceFactor={3}
      occlude="blending"
      style={{ position: "relative", display: "grid", placeItems: "center" }}
      geometry={<planeBufferGeometry args={[50, 50]} />}
      material={
        <meshPhysicalMaterial
          side={THREE.DoubleSide}
          opacity={1}
          transmission={1}
        />
      }
    >
      <motion.div
        ref={container}
        className="parent-grid grid-font"
        animate={{ x: -x / 10, y: -y / 10 }}
        transition={{
          type: "spring",
          stiffness: 500,
          damping: 20,
          mass: 0.5,
        }}
        style={{
          position: "relative",
          //   maxWidth: width <= 768 ? "90%" : "70%",
          //   margin: "0 auto",
        }}
      >
        <motion.div className="main-text-grid">
          <motion.p>
            <motion.span
              className="name"
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(1)}
              transition={{
                type: "spring",
                stiffness: 500,
                damping: 20,
                mass: 0.5,
              }}
            >
              Gabriel Junqueira Becak
            </motion.span>{" "}
            (b. 1990) is a{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(2)}
              transition={{
                type: "spring",
                stiffness: 600,
                damping: 20,
                mass: 0.5,
              }}
            >
              Berlin-based
            </motion.span>{" "}
            artist whose work explores{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(3)}
              transition={{
                type: "spring",
                stiffness: 700,
                damping: 20,
                mass: 0.5,
              }}
            >
              perceptual
            </motion.span>
            ,{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(4)}
              transition={{
                type: "spring",
                stiffness: 800,
                damping: 20,
                mass: 0.5,
              }}
            >
              cognitive
            </motion.span>{" "}
            and{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(5)}
              transition={{
                type: "spring",
                stiffness: 900,
                damping: 20,
                mass: 0.5,
              }}
            >
              phenomenological
            </motion.span>{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(6)}
              transition={{
                type: "spring",
                stiffness: 1000,
                damping: 20,
                mass: 0.5,
              }}
            >
              distortions
            </motion.span>
            .
          </motion.p>
          <motion.p>
            Originally from{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(7)}
              transition={{
                type: "spring",
                stiffness: 1100,
                damping: 20,
                mass: 0.5,
              }}
            >
              São Paulo, Brazil
            </motion.span>
            , Becak's youth was split between the city and the countryside of{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(8)}
              transition={{
                type: "spring",
                stiffness: 1200,
                damping: 20,
                mass: 0.5,
              }}
            >
              Minas Gerais
            </motion.span>{" "}
            and{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(9)}
              transition={{
                type: "spring",
                stiffness: 1300,
                damping: 20,
                mass: 0.5,
              }}
            >
              Mato Grosso
            </motion.span>
            . After graduating in{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(10)}
              transition={{
                type: "spring",
                stiffness: 1400,
                damping: 20,
                mass: 0.5,
              }}
            >
              Philosophy
            </motion.span>
            , he moved to Germany to dedicate himself to{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(11)}
              transition={{
                type: "spring",
                stiffness: 1500,
                damping: 20,
                mass: 0.5,
              }}
            >
              artistic research
            </motion.span>
            .
          </motion.p>
          <motion.p>
            Becak's multifaceted practice blends{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(12)}
              transition={{
                type: "spring",
                stiffness: 1600,
                damping: 20,
                mass: 0.5,
              }}
            >
              sound
            </motion.span>
            ,{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(13)}
              transition={{
                type: "spring",
                stiffness: 1700,
                damping: 20,
                mass: 0.5,
              }}
            >
              language
            </motion.span>
            ,{" "}
            <motion.span
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
              animate={animateProps(14)}
              transition={{
                type: "spring",
                stiffness: 1800,
                damping: 20,
                mass: 0.5,
              }}
            >
              imagery
            </motion.span>
            , and{" "}
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(15)}
              transition={{
                type: "spring",
                stiffness: 1900,
                damping: 20,
                mass: 0.5,
              }}
            >
              scent
            </motion.span>{" "}
            to engage with tensions at the intersection of{" "}
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(16)}
              transition={{
                type: "spring",
                stiffness: 2000,
                damping: 20,
                mass: 0.5,
              }}
            >
              mysticism
            </motion.span>
            ,{" "}
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(17)}
              transition={{
                type: "spring",
                stiffness: 2100,
                damping: 20,
                mass: 0.5,
              }}
            >
              science,
            </motion.span>
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(18)}
              transition={{
                type: "spring",
                stiffness: 2200,
                damping: 20,
                mass: 0.5,
              }}
            >
              conspiracy theories,
            </motion.span>
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(19)}
              transition={{
                type: "spring",
                stiffness: 2300,
                damping: 20,
                mass: 0.5,
              }}
            >
              technology
            </motion.span>
            ,
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(20)}
              transition={{
                type: "spring",
                stiffness: 2400,
                damping: 20,
                mass: 0.5,
              }}
            >
              religion,
            </motion.span>
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(21)}
              transition={{
                type: "spring",
                stiffness: 2500,
                damping: 20,
                mass: 0.5,
              }}
            >
              altered states of consciousness,
            </motion.span>
            <motion.span
              style={{ display: "inline-block" }}
              animate={animateProps(22)}
              transition={{
                type: "spring",
                stiffness: 2600,
                damping: 20,
                mass: 0.5,
              }}
            >
              and the limits of meaning and knowledge.
            </motion.span>
          </motion.p>
        </motion.div>
      </motion.div>
    </Html>
  );
}
