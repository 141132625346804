import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

export default function Model({ model, scale, material }) {
  const { nodes, materials } = useGLTF(model);
  return (
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.mesh.geometry}
      material={materials.base_material}
      rotation={[Math.PI / 2, 0, 0]}
      scale={scale}
    >
      <meshMatcapMaterial matcap={material} />
    </mesh>
  );
}

// useGLTF.preload(model);
