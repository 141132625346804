import {
  Canvas,
  extend,
  useThree,
  useLoader,
  useFrame,
} from "@react-three/fiber";
import {
  Float,
  Environment,
  CameraShake,
  ambientLight,
  Text3D,
  Center,
  Text,
  ScrollControls,
  Scroll,
  useProgress,
  ContactShadows,
  MeshDistortMaterial,
  GradientTexture,
} from "@react-three/drei";
import { Perf } from "r3f-perf";
import { Flex, Box, useFlexSize } from "@react-three/flex";
import * as THREE from "three";
import React, { Suspense, useRef, useMemo, useState, useEffect } from "react";
import TextContent from "./components/TextContent.js";
import TextContentTest from "./components/TextContent-test.js";
import Gallery from "./components/Gallery.js";
import Some3dTextStuff from "./components/Some3dTextStuff.js";
import TextContentMobile from "./components/TextContentMobile.js";
import { useWindowSize } from "@uidotdev/usehooks";

export default function App() {
  // const [pages, setPages] = useState(window.innerWidth < 767 ? 2.15 : 3);

  const { width, height } = useWindowSize();
  const isMobile = width <= 768;

  // useEffect(() => {
  //   function handleResize() {
  //     setPages(window.innerWidth < 767 ? 1.2 : 2.75);
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [pages]);

  return (
    <>
      <div className="canvas-container">
        <Canvas
          shadows
          // camera={{ fov: 40, near: 1, far: 1000 }}
          camera={{ fov: 40 }}
          gl={{
            antialias: true,
            toneMapping: THREE.ACESFilmicToneMapping,
          }}
          pixelRatio={4}
        >
          {/* <Environment preset="city" /> */}
          {/* <Perf position="top-left" /> */}
          <Suspense fallback={null}>
            {/* <ScrollControls damping={1} pages={pages}> */}
            {/* <Scroll> */}
            <Gallery />
            <Flex>
              {/* <Box>
                <Text
                  fontSize={0.4}
                  // color="#1C448E"
                  color="#A200FF"
                  maxWidth={8}
                  position={[3, 0, 3]}
                  rotation={[0, 0.2, 0]}
                  // fontWeight={600}
                  font="/Faune-TextRegular.woff"
                  // letterSpacing={0.05}
                >
                  "Against the cloistered scholarship, the cultured practice of
                  life. The language free from archaisms, without scholarship.
                  Natural and neological. The million-dollar contribution of all
                  mistakes. As we speak. As we are."
                  {/* <MeshDistortMaterial distort={0.3} speed={10} color="AAA" /> */}
              {/* </Text> */}
              {/* </Box> */}
              {/* <Box mb={0}> */}
              <Box mt={1}>
                {/* <TextContent */}
                {/* // screenSize={window.innerWidth} */}
                {/* /> */}
                {!isMobile && (
                  <Float floatIntensity={0.2} rotationIntensity={1}>
                    <TextContentTest />
                  </Float>
                )}
              </Box>
            </Flex>
            {/* </Scroll> */}
            {/* </ScrollControls> */}
          </Suspense>
          <Rig />
        </Canvas>
      </div>
      {isMobile && <TextContentMobile />}
    </>
  );
}

////
/// CAMERA RIGGING ON MOUSE MOVEMENT /////////

function Rig({ vec = new THREE.Vector3() }) {
  useFrame((state) => {
    // console.log(`delta: ${delta}`);
    // Reduce the multiplier for the pointer's x position to decrease horizontal movement sensitivity
    // Adjust the y position to keep the camera more centered and reduce vertical movement
    // The z position is set to a fixed value to prevent zooming in and out
    let targetX = state.pointer.x * 2.5; // Reduced from -0.75 to 0.2 for less horizontal movement
    let targetY = state.pointer.y * 2.5; // Adjusted for less vertical movement
    let targetZ = 15 - state.pointer.y - state.pointer.x; // A fixed distance to prevent zooming in and out
    // let targetZ = 15; // A fixed distance to prevent zooming in and out
    // let targetZ = state.pointer.x / 100 + 1; // A fixed distance to prevent zooming in and out
    // console.log(`STATE: ${state.pointer}`);
    // console.log(`targetX: ${targetX}`);
    // Use the lerp function to smoothly transition the camera to the new position
    state.camera.position.lerp(vec.set(targetX, targetY, targetZ), 0.05); // Adjusted lerp speed if necessary

    // Keep the camera looking at the center of the scene
    state.camera.lookAt(0, 0, 0);
  });
}
