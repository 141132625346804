/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useTexture, useMatcapTexture } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
// import { TextureLoader } from "three/src/loaders/TextureLoader";

export default function Croissant({ position, scale }) {
  //   const [colorMap, displacementMap, normalMap, roughnessMap] = useLoader(
  //     TextureLoader,
  //     [
  //       "soldiner_basecolor.png",
  //       "soldiner_metallic.png",
  //       "soldiner_normal.png",
  //       "soldiner_roughness.png",
  //     ]
  //   );

  const { nodes, materials } = useGLTF(
    "da2b15e5336b_8aa78156a032_realistic_croissant.glb"
  );
  const [matcapTexture] = useMatcapTexture("416BA7_A5B8D0_0D2549_65ABEB", 512);

  return (
    <>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh.geometry}
        // material={materials.base_material}
        // material={texture}
        rotation={[Math.PI / 2, 0, 0]}
        scale={scale}
        position={position}
      >
        {/* <meshStandardMaterial
          map={colorMap}
          displacementMap={displacementMap}
          normalMap={normalMap}
          roughnessMap={roughnessMap}
        /> */}
        <meshMatcapMaterial matcap={matcapTexture} />
      </mesh>
      {/* <meshMatcapMaterial matcap="/C7C7D7_4C4E5A_818393_6C6C74-512px.png" /> */}
      {/* <meshStandardMaterial map={colorMap} /> */}
    </>
  );
}

useGLTF.preload("da2b15e5336b_8aa78156a032_realistic_croissant.glb");
