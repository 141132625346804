import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Candy1(props) {
  const { nodes, materials } = useGLTF(
    "089ac4c671bb_9b8bdea608cf_wrapped_candy__3d_a.glb"
  );
  return (
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.mesh.geometry}
      material={materials.base_material}
      rotation={[Math.PI / 2, 0, 0]}
      scale={2.017}
    ></mesh>
  );
}

useGLTF.preload("089ac4c671bb_9b8bdea608cf_wrapped_candy__3d_a.glb");
