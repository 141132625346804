/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useTexture, useMatcapTexture } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
// import { TextureLoader } from "three/src/loaders/TextureLoader";

export default function Shark({ position, scale }) {
  // const colorMap = useLoader(
  //   TextureLoader,
  //   "C7C7D7_4C4E5A_818393_6C6C74-512px.png"
  // );
  // console.log(`position: ${position}`);

  const { nodes, materials } = useGLTF(
    "76e12887c110_d01017d32616_a_chrome_shark__3d_.glb"
  );
  const texture = useTexture("/C7C7D7_4C4E5A_818393_6C6C74-512px.png");
  const [matcapTexture] = useMatcapTexture("C7C7D7_4C4E5A_818393_6C6C74", 512);

  return (
    <>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh.geometry}
        // material={materials.base_material}
        // material={texture}
        rotation={[Math.PI / 2, 0, 0]}
        scale={scale}
        position={position}
      >
        <meshMatcapMaterial matcap={matcapTexture} />
      </mesh>
      {/* <meshMatcapMaterial matcap="/C7C7D7_4C4E5A_818393_6C6C74-512px.png" /> */}
      {/* <meshStandardMaterial map={colorMap} /> */}
    </>
  );
}

useGLTF.preload("76e12887c110_d01017d32616_a_chrome_shark__3d_.glb");
